<template>
  <div>
    <div
      class="qchat-float-notification"
      :class="{ shown: floatingNotification.shown }"
    >
      <span :class="{ shown: floatingNotification.shown }">
        <Icons
          class="q-mr-1"
          :type="
            `la-${
              floatingNotification.icon
                ? floatingNotification.icon
                : 'info-circle'
            }`
          "
        />
        {{
          floatingNotification.text.length > 39
            ? floatingNotification.text.substring(0, 39) + ".."
            : floatingNotification.text
        }}
      </span>
    </div>
    <div
      v-if="roomIsExpired"
      class="qchat-float-room-status"
      :class="{ shown: floatingNotification.shown }"
    >
      <span :class="{ shown: floatingNotification.shown }"> Expired </span>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
const Icons = () => import("../Icons/index.vue");

export default {
  components: { Icons },
  computed: {
    ...mapState("messenger", ["floatingNotification", "user"]),
    roomIsExpired() {
      if (this.user) {
        let roomExpiredAt = new Date(this.user.session_at).setHours(new Date(this.user.session_at).getHours() + 1)
        roomExpiredAt = new Date(roomExpiredAt).toISOString();
        // const roomExpiredAt = moment(this.user.session_at).add(1, "h");
        return new Date().toISOString() > roomExpiredAt;
      } else {
        return false;
      }
    }
  }
};
</script>
